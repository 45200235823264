import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaTrash, FaSort, FaSortUp, FaSortDown } from "react-icons/fa"; // Import sorting icons
import "../Inventory/Inventory.css"; // Import shared CSS for consistent table styling
import LoadingPage from "../../components/Loading/LoadingPage";
function InventoryDetail({ token }) {
  const { productDescription } = useParams();
  const [inventoryData, setInventoryData] = useState([]);
  const [originalData, setOriginalData] = useState([]); // To store original data for resetting
  const [editedItems, setEditedItems] = useState({});
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null }); // Sorting state
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // Fetch inventory details for the product
  const fetchInventoryDetail = useCallback(async () => {
    if (!token) return;
    try {
      const encodedproductDescription = encodeURIComponent(productDescription);
      const response = await fetch(
        `${apiUrl}/inventory/products?product_description=${encodedproductDescription}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setInventoryData(data);
      setOriginalData(data); // Store original data for reset

      // Redirect to inventory page if there are no items left
      if (data.length === 0) {
        navigate("/inventory");
      }
    } catch (error) {
      console.error("Error fetching inventory details:", error);
      navigate("/inventory");
    }
  }, [token, apiUrl, productDescription, navigate]);

  useEffect(() => {
    fetchInventoryDetail();
  }, [fetchInventoryDetail]);

  // Sorting function
  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    } else if (
      sortConfig.key === key &&
      sortConfig.direction === "descending"
    ) {
      setSortConfig({ key: null, direction: null });
      setInventoryData([...originalData]); // Reset to original order
      return;
    }

    const sortedData = [...inventoryData].sort((a, b) => {
      if (
        key === "quantity" ||
        key === "unit_price" ||
        key === "case_quantity" ||
        key === "selling_price" ||
        key === "margin"
      ) {
        return direction === "ascending" ? a[key] - b[key] : b[key] - a[key];
      }
      if (key === "date_added") {
        const dateA = new Date(a[key] || Infinity);
        const dateB = new Date(b[key] || Infinity);
        return direction === "ascending" ? dateA - dateB : dateB - dateA;
      }
      // String sorting for other keys (including distributor, unit_type, etc.)
      return direction === "ascending"
        ? a[key].localeCompare(b[key])
        : b[key].localeCompare(a[key]);
    });

    setSortConfig({ key, direction });
    setInventoryData(sortedData);
  };

  // Helper to display the correct sorting icon
  const getSortIcon = (key) => {
    if (!sortConfig.key || sortConfig.key !== key) return <FaSort />;
    if (sortConfig.direction === "ascending") return <FaSortUp />;
    if (sortConfig.direction === "descending") return <FaSortDown />;
    return <FaSort />;
  };

  const handleInputChange = (e, inventory_id, item) => {
    const { name, value } = e.target;

    setEditedItems((prevState) => {
      let updatedState = { ...prevState };
      const currentItem = updatedState[inventory_id] || {};
      updatedState[inventory_id] = { ...currentItem, [name]: value };

      // Parse relevant fields for calculations
      const caseQuantity = parseFloat(
        updatedState[inventory_id]?.case_quantity || item?.case_quantity || 0
      );
      const casePrice = parseFloat(
        updatedState[inventory_id]?.case_price || item?.case_price || 0
      );
      const unitPrice = parseFloat(
        updatedState[inventory_id]?.unit_price || item?.unit_price || 0
      );
      const quantity = parseFloat(
        updatedState[inventory_id]?.quantity || item?.quantity || 0
      );
      const unitType =
        updatedState[inventory_id]?.unit_type || item?.unit_type || "";

      if (unitType === "lb") {
        // Calculate total price for weight-based products dynamically
        updatedState[inventory_id].total_price = (unitPrice * quantity).toFixed(
          2
        );
      } else if (unitType === "case") {
        // Calculate total price for case-based products dynamically
        updatedState[inventory_id].total_price = (
          caseQuantity * casePrice
        ).toFixed(2);
      }

      const sellingPrice = parseFloat(
        updatedState[inventory_id]?.selling_price || item?.selling_price || 0
      );

      // Calculate margin dynamically
      updatedState[inventory_id].margin =
        unitPrice > 0
          ? (((sellingPrice - unitPrice) / unitPrice) * 100).toFixed(2)
          : 0;

      return updatedState;
    });
  };

  // Handle saving all changes
  const handleSaveAllClick = async () => {
    setIsLoading(true); // Start loading

    try {
      // Update inventory items
      const updateInventoryPromises = Object.keys(editedItems).map(
        async (inventory_id) => {
          try {
            // Find the original data for this inventory item
            const originalData = inventoryData.find(
              (item) => item.inventory_id === Number(inventory_id)
            );

            // Merge the edited data with the original data
            const updatedData = {
              ...originalData,
              ...editedItems[inventory_id],
            };

            const response = await fetch(
              `${apiUrl}/inventory/update-inventory/${inventory_id}`,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(updatedData),
              }
            );

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            return { productId: responseData.product_id, success: true };
          } catch (error) {
            console.error(
              `Error updating inventory_id ${inventory_id}:`,
              error
            );
            return { productId: null, success: false, error: error.message };
          }
        }
      );

      // Wait for all inventory updates and collect product IDs
      const inventoryUpdateResults = await Promise.all(updateInventoryPromises);
      const productIds = inventoryUpdateResults
        .filter((res) => res.success)
        .map((res) => res.productId);

      // Handle cases where inventory updates failed
      const failedInventoryUpdates = inventoryUpdateResults.filter(
        (res) => !res.success
      );
      if (failedInventoryUpdates.length > 0) {
        console.warn("Some inventory updates failed:", failedInventoryUpdates);
      }

      if (productIds.length === 0) {
        throw new Error("No valid products were updated. POS update skipped.");
      }

      // Update POS for all successful product IDs
      const updatePOSPromises = productIds.map(async (productId) => {
        try {
          const updateResponse = await fetch(`${apiUrl}/pos/update-pos`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              product_id: productId,
            }),
          });

          if (!updateResponse.ok) {
            console.error(
              `Failed to update POS for product_id ${productId}: ${updateResponse.statusText}`
            );
            return {
              productId,
              success: false,
              error: updateResponse.statusText,
            };
          }

          console.log(`POS successfully updated for product_id ${productId}`);
          return { productId, success: true };
        } catch (error) {
          console.error(
            `Error updating POS for product_id ${productId}:`,
            error
          );
          return { productId, success: false, error: error.message };
        }
      });

      // Wait for all POS updates
      const posUpdateResults = await Promise.all(updatePOSPromises);

      // Log any failed POS updates
      const failedPOSUpdates = posUpdateResults.filter((res) => !res.success);
      if (failedPOSUpdates.length > 0) {
        console.warn("Some POS updates failed:", failedPOSUpdates);
      }

      alert("All changes have been saved successfully and POS updated!");
      navigate("/inventory");
    } catch (error) {
      console.error("Error updating inventory or POS:", error);
      alert("Failed to save changes or update POS.");
    } finally {
      setIsLoading(false); // End loading
    }
  };

  // Handle delete action
  const handleDeleteClick = async (inventory_id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (confirmDelete) {
      try {
        const response = await fetch(`${apiUrl}/inventory/${inventory_id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        alert("Item deleted successfully");

        await fetchInventoryDetail();

        // If there are no items left, navigate back to the inventory page
        if (inventoryData.length === 0) {
          navigate("/inventory");
        }
      } catch (error) {
        console.error("Error deleting inventory item:", error);
        navigate("/inventory");
      }
    }
  };

  // Handle cancel action
  const handleCancelClick = () => {
    navigate("/inventory");
  };

  // Helper function to render inputs for the table
  const renderInput = (
    name,
    inventory_id,
    item,
    type = "number",
    step = "0.01" // Ensures fractional values
  ) => (
    <input
      type={type}
      name={name}
      value={editedItems[inventory_id]?.[name] ?? item[name]}
      onChange={(e) => handleInputChange(e, inventory_id, item)}
      step={step}
      min="0"
    />
  );

  const renderDropdown = (name, inventory_id, item) => (
    <select
      name={name}
      value={editedItems[inventory_id]?.[name] ?? item[name]}
      onChange={(e) => handleInputChange(e, inventory_id, item)}
    >
      <option value="lb">lb</option>
      <option value="case">case</option>
    </select>
  );
  if (isLoading) {
    return <LoadingPage />;
  }
  return (
    <div className="inventory-container">
      <h1>Inventory Details for {productDescription}</h1>
      <div className="inventory-actions">
        <button onClick={handleSaveAllClick}>Save All Changes</button>
        <button className="cancel-button" onClick={handleCancelClick}>
          Cancel
        </button>
      </div>
      <div className="inventory-table-wrapper">
        <div className="table-container">
          <div className="inventory-table">
            <table>
              <thead>
                <tr>
                  <th onClick={() => sortData("product_description")}>
                    Product Description {getSortIcon("product_description")}
                  </th>

                  <th onClick={() => sortData("date_added")}>
                    Date Added {getSortIcon("date_added")}
                  </th>
                  <th onClick={() => sortData("distributor")}>
                    Distributor {getSortIcon("distributor")}
                  </th>
                  <th onClick={() => sortData("unit_type")}>
                    Unit Type {getSortIcon("unit_type")}
                  </th>
                  <th onClick={() => sortData("quantity")}>
                    Quantity {getSortIcon("quantity")}
                  </th>
                  <th onClick={() => sortData("case_quantity")}>
                    Case Quantity {getSortIcon("case_quantity")}
                  </th>
                  <th onClick={() => sortData("unit_price")}>
                    Unit Price {getSortIcon("unit_price")}
                  </th>
                  <th onClick={() => sortData("case_price")}>
                    Case Price {getSortIcon("case_price")}
                  </th>
                  <th onClick={() => sortData("selling_price")}>
                    Selling Price {getSortIcon("selling_price")}
                  </th>
                  <th>Total Price</th>
                  <th onClick={() => sortData("margin")}>
                    Margin {getSortIcon("margin")}
                  </th>
                  <th className="action-header">Actions</th>
                </tr>
              </thead>
              <tbody>
                {inventoryData.map((item) => (
                  <tr key={item.inventory_id}>
                    <td>{item.product_description}</td>
                    <td>{item.date_added || ""}</td>
                    <td>
                      {renderInput(
                        "distributor",
                        item.inventory_id,
                        item,
                        "text"
                      )}
                    </td>
                    <td>
                      {renderDropdown("unit_type", item.inventory_id, item)}
                    </td>
                    <td>
                      {renderInput(
                        "quantity",
                        item.inventory_id,
                        item,
                        "number",
                        "0.01"
                      )}
                    </td>
                    <td>
                      {renderInput("case_quantity", item.inventory_id, item)}
                    </td>
                    <td>
                      {renderInput(
                        "unit_price",
                        item.inventory_id,
                        item,
                        "number",
                        "0.01"
                      )}
                    </td>
                    <td>
                      {renderInput(
                        "case_price",
                        item.inventory_id,
                        item,
                        "number",
                        "0.01"
                      )}
                    </td>
                    <td>
                      {renderInput(
                        "selling_price",
                        item.inventory_id,
                        item,
                        "number",
                        "0.01"
                      )}
                    </td>
                    <td>
                      <input
                        type="number"
                        name="total_price"
                        step="0.01"
                        value={
                          editedItems[item.inventory_id]?.total_price ??
                          parseFloat(item.total_price).toFixed(2)
                        }
                        readOnly
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="margin"
                        value={
                          editedItems[item.inventory_id]?.margin ??
                          parseFloat(item.margin).toFixed(2)
                        }
                        readOnly
                      />
                    </td>
                    <td className="action-cell">
                      <button
                        className="delete-button"
                        onClick={() => handleDeleteClick(item.inventory_id)}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InventoryDetail;
