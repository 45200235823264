import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import "./LoadingPage.css";

const phrases = [
  "Uploading inventory... Please wait.",
  "Did you know? The average grocery store has over 39,500 items.",
  "Hang tight! Fun fact: Apples float because they are 25% air.",
  "Almost there! Cereal was the first item scanned with a barcode in 1974.",
  "Grocery stores recycle millions of plastic bags each year.",
  "Did you know? Bananas are the most popular item sold in grocery stores.",
  "Stay tuned! Supermarkets generate over $682 billion in revenue annually in the U.S.",
];

function LoadingPage() {
  const [currentPhrase, setCurrentPhrase] = useState(phrases[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhrase((prevPhrase) => {
        const currentIndex = phrases.indexOf(prevPhrase);
        const nextIndex = (currentIndex + 1) % phrases.length;
        return phrases[nextIndex];
      });
    }, 10000); // Change phrase every 10 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className="loading-page">
      <div className="loader-container">
        <ReactLoading
          type={"bars"}
          color={"#003366"}
          height={100}
          width={100}
        />
        <p>{currentPhrase}</p>
      </div>
    </div>
  );
}

export default LoadingPage;
