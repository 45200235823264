import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./settings.css";
import { FaTrash } from "react-icons/fa";

function Settings({ token, setToken }) {
  const [company, setCompany] = useState("");
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [setMessage] = useState("");
  const [setIsSuccess] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      if (!token) return;
      try {
        const response = await fetch(`${apiUrl}/users/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setUsername(data.username);
        setRole(data.role);
        setCompany(data.company); // Add this line to store the company name
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    const fetchPhoneNumbers = async () => {
      if (!token) return;
      try {
        const response = await fetch(`${apiUrl}/users/phone_numbers`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setPhoneNumbers(data || []);
      } catch (error) {
        console.error("Error fetching phone numbers:", error);
      }
    };

    fetchUser();
    fetchPhoneNumbers();
  }, [token, apiUrl]);

  const changePassword = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/auth/change-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          current_password: currentPassword,
          new_password: newPassword,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setMessage(data.message);
      setIsSuccess(true);
      setCurrentPassword("");
      setNewPassword("");

      alert("Password changed successfully");
      logout();
    } catch (error) {
      console.error("Error changing password:", error);
      setMessage("Failed to change password. Please try again.");
      setIsSuccess(false);
      alert("Failed to change password. Please try again.");
    }
  };

  const addPhoneNumber = async () => {
    try {
      const response = await fetch(`${apiUrl}/users/phone_numbers/add`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ phone_numbers: [newPhoneNumber] }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setPhoneNumbers((prevNumbers) => [...prevNumbers, newPhoneNumber]);
      setNewPhoneNumber("");
    } catch (error) {
      console.error("Error adding phone number:", error);
    }
  };

  const deletePhoneNumber = async (phoneNumberToDelete) => {
    if (window.confirm("Are you sure you want to delete this phone number?")) {
      try {
        const response = await fetch(`${apiUrl}/users/phone_numbers/delete`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ phone_numbers: [phoneNumberToDelete] }),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        setPhoneNumbers((prevNumbers) =>
          prevNumbers.filter((number) => number !== phoneNumberToDelete)
        );
      } catch (error) {
        console.error("Error deleting phone number:", error);
      }
    }
  };

  const logout = async () => {
    try {
      setToken("");
      localStorage.removeItem("token");
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <div className="settings-container">
      <h1 className="settings-title">Settings</h1>
      <form onSubmit={changePassword} className="settings-form">
        <div className="setting-item">
          <label className="settings-label">Company:</label>
          <input
            type="text"
            value={company}
            readOnly
            className="settings-input"
          />
        </div>
        <div className="setting-item">
          <label className="settings-label">Username:</label>
          <input
            type="text"
            value={username}
            readOnly
            className="settings-input"
          />
        </div>
        <div className="setting-item">
          <label className="settings-label">Role:</label>
          <input value={role} readOnly className="settings-input" />
        </div>
        <div className="setting-item">
          <label className="settings-label">Current Password:</label>
          <input
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
            className="settings-input"
          />
        </div>
        <div className="setting-item">
          <label className="settings-label">New Password:</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            className="settings-input"
          />
        </div>
        <div className="setting-item">
          <label className="settings-label">Notification Phone Number:</label>
          <div className="phone-input-group">
            <input
              type="text"
              value={newPhoneNumber}
              onChange={(e) => setNewPhoneNumber(e.target.value)}
              className="settings-input"
            />
            <button
              type="button"
              onClick={addPhoneNumber}
              className="add-button"
            >
              Add
            </button>
          </div>
        </div>
        <div className="phone-number-list">
          <label>Current Phone Numbers:</label>
          <ul>
            {phoneNumbers.map((number) => (
              <li key={number} className="phone-number-item">
                {number}
                <button
                  className="delete-button"
                  onClick={() => deletePhoneNumber(number)}
                >
                  <FaTrash />
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="button-group">
          {(currentPassword || newPassword) && (
            <button type="submit" className="submit-button">
              Change Password
            </button>
          )}
          <button type="button" className="logout-button" onClick={logout}>
            Logout
          </button>
        </div>
      </form>
    </div>
  );
}

export default Settings;
