import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaPencilAlt,
  FaSort,
  FaSortUp,
  FaSortDown,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa"; // Import sorting icons
import heic2any from "heic2any";
import imageCompression from "browser-image-compression";
import Modal from "react-modal"; // Import Modal for the distributor confirmation
import LoadingPage from "../../components/Loading/LoadingPage";
import "./Inventory.css";

Modal.setAppElement("#root"); // Set the root element for accessibility

function Inventory({ token }) {
  const [inventoryData, setInventoryData] = useState([]);
  const [originalData, setOriginalData] = useState([]); // To store original data for resetting
  const [selectedFile, setSelectedFile] = useState(null);
  const [processedFile, setProcessedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessingImage, setIsProcessingImage] = useState(false);
  const fileInputRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage] = useState(1);
  const [itemsPerPage] = useState(100);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null }); // Sorting state
  const [showDistributorModal, setShowDistributorModal] = useState(false); // Distributor Modal visibility
  const [editedDistributor, setEditedDistributor] = useState(""); // User-edited distributor
  // const [fileUrl, setFileUrl] = useState(null); // File URL returned by the backend
  // const [rawText, setRawText] = useState(""); // Raw text extracted from the invoice
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";
  const maxFileSizeMB = 1;
  const [distributorSuggestions, setDistributorSuggestions] = useState([]);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isDragging, setIsDragging] = useState(false); // To track drag-and-drop state
  const [invoiceId, setInvoiceId] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  const fetchInventoryData = useCallback(async () => {
    if (!token) return;
    try {
      const response = await fetch(`${apiUrl}/products/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setInventoryData(data);
      setOriginalData(data); // Save the original data for reset
    } catch (error) {
      console.error("Error fetching inventory data:", error);
    }
  }, [token, apiUrl]);

  useEffect(() => {
    fetchInventoryData();
  }, [fetchInventoryData]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Helper function to convert HEIC to PNG
  const convertHEICToPNG = async (file) => {
    try {
      const convertedBlob = await heic2any({
        blob: file,
        toType: "image/png",
      });
      return new File([convertedBlob], file.name.replace(/\.[^/.]+$/, ".png"), {
        type: "image/png",
      });
    } catch (error) {
      console.error("Error converting HEIC to PNG:", error);
      throw new Error(
        "Failed to convert HEIC file. Please use a different format (PNG/JPEG)."
      );
    }
  };

  const handleFileChange = async (event) => {
    setIsProcessingImage(true); // Start processing state
    try {
      const file = event.target.files[0];
      if (!file) {
        alert("No file selected. Please try again.");
        return;
      }

      console.log("File selected:", file.name, file.type);

      let processedFile = file; // Default to the original file

      // Check if the file is HEIC and convert to PNG
      const isHEIC =
        file.type === "image/heic" || file.name.toLowerCase().endsWith(".heic");
      const isPDF = file.type === "application/pdf";
      if (isHEIC) {
        console.log("Detected HEIC file. Converting to PNG...");
        processedFile = await convertHEICToPNG(file);
      }
      if (!isPDF && processedFile.size > maxFileSizeMB * 1024 * 1024) {
        console.log(`Compressing image without resizing...`);

        const options = {
          maxSizeMB: maxFileSizeMB, // Target max size in MB
          maxWidthOrHeight: undefined, //
          useWebWorker: true,
        };

        try {
          processedFile = await imageCompression(processedFile, options);
          console.log(
            "Compression complete. New size:",
            (processedFile.size / (1024 * 1024)).toFixed(2),
            "MB"
          );
        } catch (error) {
          console.error("Image compression failed:", error);
          alert("Error compressing image. Try selecting a smaller file.");
          return;
        }
      }

      // Assign the processed file directly (no compression or modifications)
      setProcessedFile(processedFile);
      setSelectedFile(processedFile);

      console.log("Final processed file:", processedFile.name);
    } catch (error) {
      console.error("Error during file processing:", error);
      alert(error.message || "An error occurred while processing the file.");
    } finally {
      setIsProcessingImage(false); // End processing state
    }
  };

  const handleConfirmDistributor = async () => {
    if (!editedDistributor.trim()) {
      alert("Distributor name cannot be empty.");
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(`${apiUrl}/inventory/confirm-distributor`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          invoice_id: invoiceId, // Pass stored invoice ID
          distributor_name: editedDistributor,
          file_url: fileUrl,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Confirmed distributor processing completed:", data);
      setShowDistributorModal(false);
      setIsLoading(false);
      navigate("/inventory_edit", { state: { products: data.data || [] } });
    } catch (error) {
      console.error("Error confirming distributor:", error);
      setIsLoading(false);
      alert("Failed to process invoice with the confirmed distributor name.");
    }
  };

  const handleAddInventoryClick = async () => {
    if (!processedFile) {
      alert("Please select a file to upload.");
      return;
    }

    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("file", processedFile);

      const response = await fetch(
        `${apiUrl}/inventory/azure-upload-and-process-invoice`,
        {
          method: "POST",
          headers: { Authorization: `Bearer ${token}` },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.require_confirmation) {
        console.log("Distributor confirmation required:", data.suggested_name);
        setEditedDistributor();
        setInvoiceId(data.invoice_id); // Store only the invoice ID
        setFileUrl(data.file_url);
        setShowDistributorModal(true);
        setIsLoading(false);
        return;
      }

      console.log("Processed Invoice Data:", data);
      navigate("/inventory_edit", { state: { products: data.data || [] } });
      setIsLoading(false);
    } catch (error) {
      console.error("Error uploading photo:", error);
      alert("Failed to process invoice. Please try again.");
      setIsLoading(false);
    }
  };

  const handleDistributorInputChange = async (e) => {
    const query = e.target.value;
    setEditedDistributor(query);

    if (query.trim().length > 1) {
      try {
        const response = await fetch(
          `${apiUrl}/inventory/distributors/search?query=${encodeURIComponent(
            query
          )}`,
          {
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setDistributorSuggestions(data.distributors || []);
        }
      } catch (error) {
        console.error("Error searching distributors:", error);
      }
    } else {
      setDistributorSuggestions([]);
    }
  };

  const handleDistributorSuggestionClick = (name) => {
    setEditedDistributor(name);
    setDistributorSuggestions([]);
  };

  const handleEditItem = (item) => {
    console.log(item.product_description);
    navigate(`/inventory_detail/${item.product_description}`);
  };

  // Sort Data
  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    } else if (
      sortConfig.key === key &&
      sortConfig.direction === "descending"
    ) {
      setSortConfig({ key: null, direction: null });
      setInventoryData([...originalData]); // Reset to original order
      return;
    }

    const sortedData = [...inventoryData].sort((a, b) => {
      if (key === "quantity" || key === "selling_price") {
        return direction === "ascending" ? a[key] - b[key] : b[key] - a[key];
      }
      if (key === "expiration_date") {
        const dateA = new Date(a.expiration_date || Infinity);
        const dateB = new Date(b.expiration_date || Infinity);
        return direction === "ascending" ? dateA - dateB : dateB - dateA;
      }
      // String sorting for other keys (e.g. brand, product_name)
      return direction === "ascending"
        ? a[key].localeCompare(b[key])
        : b[key].localeCompare(a[key]);
    });

    setSortConfig({ key, direction });
    setInventoryData(sortedData);
  };

  // Filter inventory data by product_description
  const filteredInventoryData = inventoryData.filter(
    (item) =>
      item.product_description
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      (item.distributor &&
        item.distributor.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const totalPages = Math.ceil(filteredInventoryData.length / itemsPerPage);

  const paginatedInventoryData = Array.from({ length: totalPages }, (_, i) =>
    filteredInventoryData.slice(i * itemsPerPage, (i + 1) * itemsPerPage)
  );

  const getSortIcon = (key) => {
    if (!sortConfig.key || sortConfig.key !== key) return <FaSort />;
    if (sortConfig.direction === "ascending") return <FaSortUp />;
    if (sortConfig.direction === "descending") return <FaSortDown />;
    return <FaSort />;
  };

  if (isLoading || isProcessingImage) {
    return <LoadingPage />;
  }

  const toggleRow = (index) => {
    const newExpandedRows = new Set(expandedRows);
    if (newExpandedRows.has(index)) {
      newExpandedRows.delete(index);
    } else {
      newExpandedRows.add(index);
    }
    setExpandedRows(newExpandedRows);
  };

  return (
    <div className="inventory-container">
      <h1>Inventory</h1>

      <div className="add-or-upload-container">
        <div className="manual-add-container">
          <button
            className="manual-add-button"
            onClick={() => {
              navigate("/inventory_edit", { state: { products: [] } });
            }}
          >
            Add Inventory Item
          </button>
        </div>

        <div className="divider-or">or</div>

        <div
          className={`file-upload-container ${isDragging ? "drag-over" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
            if (fileInputRef.current) fileInputRef.current.click();
          }}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragging(true);
          }}
          onDragEnter={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragging(true);
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragging(false);
          }}
          onDrop={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragging(false);

            if (e.dataTransfer.files.length > 0) {
              handleFileChange({ target: { files: e.dataTransfer.files } });
            }
          }}
        >
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*, .heic, application/pdf"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <div className="upload-box">
            <span>Drag and drop a photo here or click to upload</span>
          </div>
          {selectedFile && (
            <span className="file-name">{selectedFile.name}</span>
          )}
          {selectedFile && (
            <button
              className="add-inventory-button"
              onClick={(e) => {
                e.stopPropagation();
                handleAddInventoryClick();
              }}
            >
              Confirm Upload
            </button>
          )}
        </div>
      </div>

      <div className="search-container">
        <input
          className="search-input"
          type="text"
          placeholder="Search inventory"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {isMobile ? (
        <table className="w-full">
          <thead>
            <tr>
              <th onClick={() => sortData("distributor")}>
                Distributor {getSortIcon("distributor")}
              </th>
              <th onClick={() => sortData("product_description")}>
                Product {getSortIcon("product_description")}
              </th>
              <th onClick={() => sortData("quantity")}>
                Qty {getSortIcon("quantity")}
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {paginatedInventoryData[currentPage - 1]?.map((item, index) => (
              <React.Fragment key={index}>
                <tr className="border-b">
                  <td className="py-2 px-3">{item.distributor || "N/A"}</td>
                  <td className="py-2 px-3">{item.product_description}</td>
                  <td className="py-2 px-3">
                    {item.quantity
                      ? parseFloat(item.quantity).toFixed(2)
                      : "N/A"}
                  </td>
                  <td className="py-2 px-3">
                    <div className="flex justify-end gap-2">
                      <button onClick={() => handleEditItem(item)}>
                        <FaPencilAlt />
                      </button>
                      <button onClick={() => toggleRow(index)}>
                        {expandedRows.has(index) ? (
                          <FaChevronUp />
                        ) : (
                          <FaChevronDown />
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
                {expandedRows.has(index) && (
                  <tr className="bg-gray-50 border-b">
                    <td colSpan={4} className="p-3">
                      <div className="grid grid-cols-2 gap-3">
                        <div className="text-sm">
                          <span className="font-medium">Unit Type:</span>{" "}
                          {item.unit_type || "N/A"}
                        </div>
                        <div className="text-sm">
                          <span className="font-medium">Selling:</span> $
                          {item.selling_price
                            ? parseFloat(item.selling_price).toFixed(2)
                            : "N/A"}
                        </div>
                        <div className="text-sm">
                          <span className="font-medium">Expiration:</span>{" "}
                          {item.expiration_date || "N/A"}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="inventory-table-wrapper">
          <div className="table-container">
            <div className="inventory-table">
              <table>
                <thead>
                  <tr>
                    <th onClick={() => sortData("distributor")}>
                      Distributor {getSortIcon("distributor")}
                    </th>
                    <th onClick={() => sortData("product_description")}>
                      Product Description {getSortIcon("product_description")}
                    </th>
                    <th onClick={() => sortData("quantity")}>
                      Quantity {getSortIcon("quantity")}
                    </th>
                    <th onClick={() => sortData("unit_type")}>
                      Unit Type {getSortIcon("unit_type")}
                    </th>
                    <th onClick={() => sortData("selling_price")}>
                      Selling Price {getSortIcon("selling_price")}
                    </th>
                    <th onClick={() => sortData("expiration_date")}>
                      Closest TurnOver Date{getSortIcon("expiration_date")}
                    </th>
                    <th className="action-header">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedInventoryData[currentPage - 1]?.length > 0 ? (
                    paginatedInventoryData[currentPage - 1].map(
                      (item, index) => (
                        <tr key={index}>
                          <td>{item.distributor || "N/A"}</td>
                          <td>{item.product_description}</td>
                          <td>
                            {item.quantity
                              ? parseFloat(item.quantity).toFixed(2)
                              : "N/A"}
                          </td>
                          <td>{item.unit_type || "N/A"}</td>
                          <td>
                            {item.selling_price
                              ? parseFloat(item.selling_price).toFixed(2)
                              : "N/A"}
                          </td>
                          <td>{item.expiration_date || "N/A"}</td>
                          <td className="action-cell">
                            <button
                              className="edit-button"
                              onClick={() => handleEditItem(item)}
                            >
                              <FaPencilAlt />
                            </button>
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan="10" className="no-data-row">
                        No inventory items available. Please add some items.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      <Modal
        isOpen={showDistributorModal}
        onRequestClose={() => setShowDistributorModal(false)}
        className="modal"
        overlayClassName="modal-overlay"
      >
        <div className="modal-header">
          <h2>Confirm Distributor Name</h2>
        </div>
        <div className="modal-body">
          <input
            type="text"
            value={editedDistributor}
            onChange={handleDistributorInputChange}
            className="distributor-input"
            placeholder="Enter or search distributor"
          />
          {distributorSuggestions.length > 0 && (
            <div className="distributor-suggestions">
              {distributorSuggestions.map((name, index) => (
                <div
                  key={index}
                  className="suggestion-item"
                  onClick={() => handleDistributorSuggestionClick(name)}
                >
                  {name}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="modal-footer">
          <button
            onClick={() => setShowDistributorModal(false)}
            className="cancel-button"
          >
            Cancel
          </button>
          <button onClick={handleConfirmDistributor} className="confirm-button">
            Confirm
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default Inventory;

// const handleFileChange = async (event) => {
//   setIsProcessingImage(true); // Start processing state
//   try {
//     const file = event.target.files[0];
//     if (!file) {
//       alert("No file selected. Please try again.");
//       return;
//     }

//     console.log("File selected:", file.name, file.type);

//     // Check for file type
//     const isHEIC =
//       file.type === "image/heic" || file.name.toLowerCase().endsWith(".heic");
//     const isPDF = file.type === "application/pdf";

//     if (isHEIC) {
//       console.log("Detected HEIC file. Converting to PNG...");
//       const convertedFile = await convertHEICToPNG(file);
//       await processImageFile(convertedFile);
//     } else if (isPDF) {
//       console.log("Detected PDF file. Continue");
//       await processImageFile(file);
//     } else {
//       console.log("Processing standard image file...");
//       await processImageFile(file);
//     }
//   } catch (error) {
//     console.error("Error during file processing:", error);
//     alert(error.message || "An error occurred while processing the file.");
//   } finally {
//     setIsProcessingImage(false); // End processing state
//   }
// };

// Helper function to process image files
// const processImageFile = async (file) => {
//   // Check if the file is a PDF
//   const isPDF = file.type === "application/pdf";

//   if (isPDF) {
//     console.log("Detected PDF file. Skipping image processing...");
//     setProcessedFile(file); // Use the original file as the processed file
//     setSelectedFile(file); // Set the original file as the selected file
//     console.log("PDF processing skipped:", file.name);
//     return; // Exit early since no further processing is required
//   }
//   const img = new Image();
//   const reader = new FileReader();

//   const brightenedFile = await new Promise((resolve, reject) => {
//     reader.onload = (e) => {
//       img.src = e.target.result;
//     };
//     reader.readAsDataURL(file);

//     img.onload = () => {
//       const canvas = document.createElement("canvas");
//       const ctx = canvas.getContext("2d");

//       canvas.width = img.width;
//       canvas.height = img.height;

//       ctx.filter = "brightness(1.2)";
//       ctx.drawImage(img, 0, 0);

//       canvas.toBlob(
//         (blob) => {
//           if (blob) {
//             resolve(new File([blob], file.name, { type: "image/png" }));
//           } else {
//             reject(new Error("Failed to enhance image brightness."));
//           }
//         },
//         "image/png",
//         1
//       );
//     };

//     img.onerror = () =>
//       reject(new Error("Failed to load image for processing."));
//   });

//   setProcessedFile(brightenedFile);
//   setSelectedFile(brightenedFile);

//   console.log(
//     "Image processing completed successfully:",
//     brightenedFile.name
//   );
// };

// const handleDistributorSearch = async (query) => {
//   try {
//     const response = await fetch(
//       `${apiUrl}/inventory/distributors/search?query=${encodeURIComponent(
//         query
//       )}`,
//       {
//         method: "GET",
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );

//     if (response.ok) {
//       const data = await response.json();
//       return data.distributors || [];
//     } else {
//       console.error(
//         "Failed to fetch distributor suggestions:",
//         response.status
//       );
//       return [];
//     }
//   } catch (error) {
//     console.error("Error searching distributors:", error);
//     return [];
//   }
// };

// const handleAddInventoryClick = async () => {
//   if (!processedFile) {
//     alert("Please select a file to upload.");
//     return;
//   }

//   setIsLoading(true);

//   try {
//     const isPDF = processedFile.type === "application/pdf";

//     let fileToUpload = processedFile;

//     // If not a PDF, compress the image file before uploading
//     if (!isPDF) {
//       const options = {
//         maxSizeMB: maxFileSizeMB,
//         maxWidthOrHeight: 1920,
//         useWebWorker: true,
//       };
//       fileToUpload = await imageCompression(processedFile, options);

//       if (fileToUpload.size > maxFileSizeMB * 1024 * 1024) {
//         alert(
//           `The compressed file size exceeds ${maxFileSizeMB} MB. Please upload a smaller file.`
//         );
//         setIsLoading(false);
//         return;
//       }
//     }

//     // Prepare the form data
//     const formData = new FormData();
//     formData.append("file", fileToUpload);

//     // Send the file to the backend
//     const response = await fetch(`${apiUrl}/inventory/upload-invoice-photo`, {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//       body: formData,
//     });

//     if (!response.ok) {
//       throw new Error(`HTTP error! Status: ${response.status}`);
//     }

//     const data = await response.json();

//     // Set the extracted raw text and file URL
//     setRawText(data.raw_text || "");
//     setFileUrl(data.file_url);

//     // Handle distributor detection
//     if (data.detected_distributor) {
//       console.log(`Distributor found: ${data.detected_distributor}`);
//       // Proceed to process the invoice
//       await processInvoice(
//         data.detected_distributor,
//         data.file_url,
//         data.raw_text
//       );
//     } else {
//       console.log("Distributor not found. Prompting user for input.");
//       setEditedDistributor(""); // Leave empty for user input
//       setShowDistributorModal(true);
//       setIsLoading(false);
//     }
//   } catch (error) {
//     console.error("Error uploading photo:", error);
//     alert("Failed to process invoice. Please try again.");
//     setIsLoading(false);
//   }
// };

// const handleConfirmDistributor = async () => {
//   if (!editedDistributor.trim()) {
//     alert("Distributor name cannot be empty.");
//     return;
//   }

//   setIsLoading(true);
//   const payload = {
//     distributor_name: editedDistributor.trim(),
//     file_url: fileUrl,
//     raw_text: rawText,
//   };

//   try {
//     const response = await fetch(`${apiUrl}/inventory/process-invoice`, {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(payload),
//     });

//     if (!response.ok) {
//       throw new Error(`HTTP error! Status: ${response.status}`);
//     }

//     const { task_id } = await response.json();

//     await pollTask(
//       task_id,
//       (result) => {
//         const { distributor_id, products } = result;

//         console.log("Final products:", products);

//         setShowDistributorModal(false);
//         setIsLoading(false);
//         navigate("/inventory_edit", {
//           state: {
//             products,
//             distributor_id,
//           },
//         });
//       },
//       (errorMessage) => {
//         setIsLoading(false);
//         alert(errorMessage);
//       }
//     );
//   } catch (error) {
//     console.error("Error confirming distributor:", error);
//     setIsLoading(false);
//     alert("Failed to initiate invoice processing.");
//   }
// };

// Helper function to process the invoice when the distributor is found
// const processInvoice = async (distributorName, fileUrl, rawText) => {
//   setIsLoading(true);
//   try {
//     const payload = {
//       distributor_name: distributorName,
//       file_url: fileUrl,
//       raw_text: rawText,
//     };

//     const response = await fetch(`${apiUrl}/inventory/process-invoice`, {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(payload),
//     });

//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }

//     const { task_id } = await response.json();

//     // Use the pollTask function to wait for the task completion
//     await pollTask(
//       task_id,
//       (result) => {
//         const { distributor_id, products } = result;
//         console.log("Final products:", products);

//         setIsLoading(false); // Stop loading state after completion
//         navigate("/inventory_edit", {
//           state: {
//             products,
//             distributor_id,
//           },
//         });
//       },
//       (errorMessage) => {
//         setIsLoading(false);
//         alert(errorMessage);
//       }
//     );
//   } catch (error) {
//     console.error("Error processing invoice:", error);
//     setIsLoading(false);
//     alert("Failed to process invoice automatically. Please try again.");
//   }
// };

// // Helper function to poll task status
// const pollTask = async (task_id, onComplete, onError) => {
//   const interval = 2000; // Poll every 2 seconds
//   const maxAttempts = 360; // Stop polling after 30 minutes
//   let attempts = 0;

//   const pollTaskStatus = async () => {
//     const statusResponse = await fetch(`${apiUrl}/task/${task_id}/status`, {
//       method: "GET",
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//     });

//     if (!statusResponse.ok) {
//       throw new Error(`HTTP error! status: ${statusResponse.status}`);
//     }

//     return await statusResponse.json();
//   };

//   const executePolling = async () => {
//     if (attempts >= maxAttempts) {
//       throw new Error("Task timeout. Please try again later.");
//     }

//     const statusData = await pollTaskStatus();

//     if (statusData.status === "processing_chunk") {
//       const currentChunk = statusData.result.chunk_number;
//       const totalChunks = statusData.result.total_chunks;
//       console.log(`Processing chunk ${currentChunk} of ${totalChunks}...`);
//     } else if (statusData.status === "completed") {
//       console.log("Task completed. Finalizing results...");
//       onComplete(statusData.result); // Call the onComplete callback
//       return;
//     } else if (statusData.status === "failed") {
//       onError(
//         `Failed to process invoice: ${
//           statusData.result.error || "Unknown error"
//         }`
//       );
//       return;
//     }

//     attempts++;
//     setTimeout(executePolling, interval);
//   };

//   await executePolling();
// };
