import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { AuthProvider } from "../src/contexts/AuthContext"; // Import the AuthProvider

// Get the root container element
const container = document.getElementById("root");

// Create the React root
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthProvider>
      {" "}
      {/* Wrap your app with the AuthProvider */}
      <App />
    </AuthProvider>
  </React.StrictMode>
);
